import i18n from 'i18next';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { BladeActions, BladeProps, withRegisterBlade } from 'react-tools';

import { cleanupImport } from '../../duck/actions';
import { NewnityApi } from '../../duck/api';
import {
  selectCompanyId,
  selectImportCheckFetchState,
  selectImportFetchState,
  selectImportParseResult,
  selectImportResult,
} from '../../duck/selectors';
import { importFile, parseImportFile } from '../../duck/thunks';
import { Import, ImportActions, ImportProps } from './import';

// export interface ImportContainerProps extends BladeProps {}

const mapStateToProps: MapStateToProps<ImportProps, BladeProps, any> = (state, ownProps) => {
  return {
    companyId: selectCompanyId(state),
    importTemplateUrl: NewnityApi.getImportTemplateUrl(),
    importTemplateDriveUrl: NewnityApi.getImportTemplateDriveUrl(),
    importFetchState: selectImportFetchState(state),
    importFileCheckFetchState: selectImportCheckFetchState(state),
    importFileParseResult: selectImportParseResult(state),
    importFileResult: selectImportResult(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<ImportActions, BladeProps> = (dispatch, ownProps) => {
  return {
    onClose: () => dispatch(cleanupImport()),
    closeImport: () => dispatch(BladeActions.closeBlade(ownProps.bladeId, cleanupImport())),
    parseImportFile: (serverFilePath: string) => dispatch<any>(parseImportFile(serverFilePath)),
    startImport: (serverFilePath: string, companyId: number) =>
      dispatch<any>(importFile(serverFilePath, companyId)),
    resetImport: () => {
      dispatch(cleanupImport());
      dispatch(BladeActions.closeChildrenBlades(ownProps.bladeId));
    },
  };
};

export const ImportBladeType = 'Import';

const bladeId = (props: BladeProps) => 'IMPORT';

const bladeConfig = {
  size: { defaultWidth: 500, minWidth: 300 },
  bladeType: ImportBladeType,
  id: bladeId,
  title: (_: any) => i18n.t('newnity.import.blade.title'),
  allowMultipleInstances: false,
};

export const ImportContainer = withRegisterBlade<BladeProps>(bladeConfig)(
  connect(mapStateToProps, mapDispatchToProps)(Import)
);
