import { Uploader } from 'app/components';
import { FileUploadResult } from 'app/components/upload/types';
import { FetchState, ImportFileParseResult, ImportFileResult } from 'app/newnity/duck/types';
import classNames from 'classnames';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import CloudDownload from '@material-ui/icons/CloudDownload';

import { useStyles } from './import.jss';
import { ImportSummaryContainer } from './importSummaryContainer';

import { BladeProps, useBladeClosing } from 'react-tools';

export interface ImportProps {
  companyId: number;
  importTemplateUrl: string;
  importTemplateDriveUrl: string;
  importFetchState: FetchState<string>;
  importFileCheckFetchState: FetchState<string>;
  importFileParseResult: ImportFileParseResult;
  importFileResult: ImportFileResult;
}

export interface ImportActions {
  onClose: () => void;
  resetImport: () => void;
  startImport: (serverFilePath: string, companyId: number) => void;
  parseImportFile: (serverFilePath: string) => void;
  closeImport: () => void;
}

type Props = ImportProps & ImportActions & BladeProps;

export const Import: React.FunctionComponent<Props> = React.memo((props) => {
  const [t] = useTranslation();
  const classes = useStyles({});
  const [activeStep, setActiveStep] = useState(0);
  const uploadedFilePath = useRef('');

  const { resetImport, parseImportFile, startImport } = props;

  useBladeClosing(
    props.bladeId,
    () => true,
    () => {
      props.onClose();
    }
  );

  const goBack = useCallback(() => {
    if (activeStep > 1) {
      resetImport();
    }
    setActiveStep(Math.max(0, activeStep - 1));
  }, [activeStep, resetImport]);

  const goBackTwice = useCallback(() => {
    if (activeStep > 1) {
      resetImport();
    }
    setActiveStep(Math.max(0, activeStep - 2));
  }, [activeStep, resetImport]);

  const goForward = useCallback(() => {
    setActiveStep(Math.max(0, activeStep + 1));
  }, [activeStep]);

  const onFileUploaded = useCallback(
    (files: FileUploadResult[]) => {
      let path = '';
      if (files.length > 0 && files[0].successful) {
        path = files[0].remotePath;
      }
      uploadedFilePath.current = path;
      setActiveStep(2);
      parseImportFile(path);
    },
    [parseImportFile]
  );

  const onOpenInDrive = useCallback(() => {
    setActiveStep(1);
  }, []);

  const onImport = useCallback(() => {
    if (uploadedFilePath) {
      startImport(uploadedFilePath.current, props.companyId);
    }
  }, [uploadedFilePath, props.companyId, startImport]);

  return (
    <div className={classes.container}>
      <Stepper orientation="vertical" activeStep={activeStep}>
        <Step key="0" disabled={props.importFileCheckFetchState.isFetching}>
          <StepLabel>{t<string>('newnity.import.step.getTemplate.title')}</StepLabel>
          <StepContent>
            <Button
              // onClick={onTemplateFileDownload}
              className={classes.button}
              href={props.importTemplateUrl}
              variant="contained"
            >
              <CloudDownload className={classes.buttonIcon} />
              {t<string>('newnity.import.step.getTemplate.downloadFile')}
            </Button>
            <Button
              className={classes.button}
              onClick={onOpenInDrive}
              target="_blank"
              variant="contained"
              href={props.importTemplateDriveUrl}
            >
              <SvgIcon className={classes.buttonIcon}>
                <svg viewBox="0 0 139 120.4">
                  <radialGradient
                    id="a"
                    cx="-254.81979"
                    cy="705.83588"
                    gradientTransform="matrix(2.827 1.6322 -1.6322 2.827 2092.1199 -1494.5786)"
                    gradientUnits="userSpaceOnUse"
                    r="82.978401"
                  >
                    <stop offset="0" stopColor="#4387fd" />
                    <stop offset=".65" stopColor="#3078f0" />
                    <stop offset=".9099" stopColor="#2b72ea" />
                    <stop offset="1" stopColor="#286ee6" />
                  </radialGradient>
                  <radialGradient
                    id="b"
                    cx="-254.8174"
                    cy="705.83691"
                    gradientTransform="matrix(2.827 1.6322 -1.6322 2.827 2092.1199 -1494.5786)"
                    gradientUnits="userSpaceOnUse"
                    r="82.973"
                  >
                    <stop offset="0" stopColor="#ffd24d" />
                    <stop offset="1" stopColor="#f6c338" />
                  </radialGradient>
                  <path d="m24.2 120.4-24.2-41.9 45.3-78.5 24.2 41.9z" fill="#0da960" />
                  <path d="m24.2 120.4 24.2-41.9h90.6l-24.2 41.9z" fill="url(#a)" />
                  <path d="m139 78.5h-48.4l-45.3-78.5h48.4z" fill="url(#b)" />
                  <path d="m69.5 78.5h-21.1l10.5-18.3-34.7 60.2z" fill="#2d6fdd" />
                  <path d="m90.6 78.5h48.4l-58.9-18.3z" fill="#e5b93c" />
                  <path d="m58.9 60.2 10.6-18.3-24.2-41.9z" fill="#0c9b57" />
                </svg>
              </SvgIcon>
              {t<string>('newnity.import.step.getTemplate.openInDrive')}
            </Button>
            <br />
            <Button
              className={classNames(classes.button, classes.lowercaseText)}
              style={{ textTransform: 'lowercase' }}
              variant="text"
              onClick={goForward}
            >
              ({t<string>('newnity.import.step.getTemplate.skipThisStep')})
            </Button>
          </StepContent>
        </Step>
        <Step key="1" disabled={props.importFileCheckFetchState.isFetching}>
          <StepLabel>{t<string>('newnity.import.step.uploadFile.title')}</StepLabel>
          <StepContent>
            <Uploader
              multiple={false}
              accept=".xlsx"
              filesAreContent={false}
              showButtons={false}
              startUpload={true}
              onUploadComplete={onFileUploaded}
            />
            <Button className={classes.button} variant="text" onClick={goBack}>
              {t<string>('back')}
            </Button>
          </StepContent>
        </Step>
        <Step key="2" active={activeStep >= 2}>
          <StepLabel>{t<string>('newnity.import.step.checkFileData.title')}</StepLabel>
          <StepContent>
            {!props.importFileCheckFetchState.fetchCompleted ? (
              <>
                <CircularProgress size={12} className={classes.progress} />
                <Typography variant="inherit">
                  {t<string>('newnity.import.step.checkFileData.progressMessage')}
                </Typography>
              </>
            ) : (
              <>
                <ImportSummaryContainer
                  parentBladeId={props.bladeId}
                  rows={[
                    {
                      entityName: t('newnity.locations'),
                      detailsBladeTitle: t('newnity.import.summary.locationsSkippedTitle'),
                      data: props.importFileParseResult.locations,
                    },
                    {
                      entityName: t('newnity.devices'),
                      detailsBladeTitle: t('newnity.import.summary.devicesSkippedTitle'),
                      data: props.importFileParseResult.devices,
                    },
                    {
                      entityName: t('newnity.deviceSettings'),
                      detailsBladeTitle: t('newnity.import.summary.deviceSettingsSkippedTitle'),
                      data: props.importFileParseResult.deviceSettings,
                    },
                  ]}
                />
                {activeStep === 2 && (
                  <>
                    <Typography className={classes.errorsMessage}>
                      {t<string>('newnity.import.step.checkFileData.message')}
                    </Typography>
                    <Button className={classes.button} variant="text" onClick={goBack}>
                      {t<string>('back')}
                    </Button>
                    <Button
                      className={classes.button}
                      variant="contained"
                      color="secondary"
                      onClick={goForward}
                    >
                      {t<string>('next')}
                    </Button>
                  </>
                )}
              </>
            )}
          </StepContent>
        </Step>
        <Step key="3">
          <StepLabel>{t<string>('newnity.import.step.importData.title')}</StepLabel>
          <StepContent>
            <FormControl className={classes.bottomSpacing}>
              <Typography>
                {t<string>('newnity.import.step.importData.duplicatesMessage')}
              </Typography>
              <RadioGroup
                aria-label="duplicates"
                name="duplicates"
                value="skip" /*onChange={handleChange}*/
              >
                <FormControlLabel
                  value="update"
                  disabled={true}
                  control={<Radio color="secondary" />}
                  label={t<string>('newnity.import.step.importData.radio.update')}
                />
                <FormControlLabel
                  value="skip"
                  control={<Radio color="secondary" />}
                  label={t<string>('newnity.import.step.importData.radio.skip')}
                />
              </RadioGroup>
              {/* <FormHelperText>labelPlacement start</FormHelperText> */}
            </FormControl>
            <br />
            {props.importFetchState.fetchCompleted && (
              <>
                <Typography>
                  {t<string>('newnity.import.step.importData.completedMessage')}
                </Typography>
                <ImportSummaryContainer
                  parentBladeId={props.bladeId}
                  excludeColumnNameFromDetails={true}
                  rows={[
                    {
                      entityName: t('newnity.locations'),
                      detailsBladeTitle: t('newnity.import.summary.locationsSkippedTitle'),
                      data: props.importFileResult.locations,
                    },
                    {
                      entityName: t('newnity.devices'),
                      detailsBladeTitle: t('newnity.import.summary.devicesSkippedTitle'),
                      data: props.importFileResult.devices,
                    },
                  ]}
                />
                <div className={classes.bottomSpacing} />
              </>
            )}
            {props.importFetchState.isFetching ? (
              <>
                <CircularProgress size={12} className={classes.progress} />
                <Typography variant="inherit" className={classes.bottomSpacing}>
                  {t<string>('newnity.import.step.importData.progressMessage')}
                </Typography>
              </>
            ) : (
              <>
                <Button className={classes.button} variant="text" onClick={goBackTwice}>
                  {t<string>('back')}
                </Button>
                {!props.importFetchState.fetchCompleted && (
                  <Button
                    variant="contained"
                    className={classes.button}
                    color="secondary"
                    onClick={onImport}
                  >
                    {t<string>('newnity.import')}
                  </Button>
                )}
                {props.importFetchState.fetchCompleted && (
                  <Button
                    variant="contained"
                    className={classes.button}
                    color="secondary"
                    onClick={props.closeImport}
                  >
                    {t<string>('close')}
                  </Button>
                )}
              </>
            )}
          </StepContent>
        </Step>
      </Stepper>
    </div>
  );
});
