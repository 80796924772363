import i18next from 'i18next';
import {
  BladeActions,
  BladeSelectors,
  denormalize,
  openBlade,
  pushNotification,
} from 'react-tools';
import { Dispatch } from 'redux';

import i18n from '../../../localization/i18n';
import {
  DataStoreActions,
  DataStoreSelectors,
  EntityType,
  NCompany,
  NDevice,
  NLocation,
  NStation,
  OnHold,
  TemplateLibrary,
  Visual,
  Stream,
  Zone,
} from '../../dataStore';
import { Channel, DataStoreState } from '../../dataStore/types';
import { CompanyBladeType } from '../blades/company';
import { DeviceBladeType } from '../blades/device/edit/deviceContainer';
import { OnHoldEditBladeType } from '../blades/onhold/edit/onHoldEditContainer';
import { OnHoldListBladeType } from '../blades/onhold/list/onHoldListContainer';
import { SearchBladeType } from '../blades/search/searchContainer';
import { VisualsEditBladeType } from '../blades/visuals/edit/visualsEditContainer';
import { VisualsListBladeType } from '../blades/visuals/list/visualsListContainer';
import { ZoneEditBladeType } from '../blades/zone/edit/zoneEditContainer';
import { ZoneListBladeType } from '../blades/zone/list/zoneListContainer';
import {
  cleanupStationEdit,
  companyDataFetchError,
  companyDataFetchRequest,
  companyDataFetchSuccess,
  companySaveError,
  companySaveRequest,
  companySaveSuccess,
  defaultParentWorkgroupError,
  defaultParentWorkgroupRequest,
  defaultParentWorkgroupSuccess,
  deviceDeleteError,
  deviceDeleteRequest,
  deviceDeleteSuccess,
  deviceFinishEdit,
  deviceSaveError,
  deviceSaveRequest,
  deviceSaveSuccess,
  fetchPlaylistsForZoneSuccess,
  importFileError,
  importFileRequest,
  importFileSuccess,
  locationDeleteError,
  locationDeleteRequest,
  locationDeleteSuccess,
  locationFinishEdit,
  locationSaveError,
  locationSaveRequest,
  locationSaveSuccessful,
  parseImportFileError,
  parseImportFileRequest,
  parseImportFileSuccess,
  searchSetSearched,
  setCompanyError,
  setCompanyRequest,
  setCompanySuccess,
  startupRequest,
  startupSuccess,
  stationDeleteError,
  stationDeleteRequest,
  stationDeleteSuccess,
  stationFetchError,
  stationFetchRequest,
  stationFetchSuccess,
  stationFinishEdit,
  stationSaveError,
  stationSaveRequest,
  stationSaveSuccessful,
  updateCurrentParentWorkgroup,
  channelDeleteRequest,
  channelDeleteSuccess,
  channelDeleteError,
  channelFetchRequest,
  channelFetchSuccessful,
  channelSaveRequest,
  channelSaveSuccessful,
  channelFetchError,
  changeWorkgroupAuthRequest,
  setWorkgroupAuthSuccess,
  setWorkgroupAuthError,
} from './actions';
import { NewnityApi } from './api';
import { emptySearchFields } from './reducer';
import {
  DeviceMode,
  NewnityState,
  ProgramAsset,
  SearchEntity,
  SearchFields,
  StreamAudioOutput,
} from './types';

export function newnityStartup() {
  return async (dispatch: Dispatch<any>) => {
    dispatch(startupRequest);
    dispatch(fetchCompanies());
    try {
      await NewnityApi.anthemAuthentication();
      dispatch(fetchPrograms());
      dispatch(startupSuccess());
    } catch (err) {
      dispatch(
        pushNotification(
          `${i18next.t('newnity.startup.anthemAuthenticationFailure')}: ${err}`,
          'error'
        )
      );
    }
  };
}

export function fetchCompanies() {
  return async (dispatch: Dispatch<any>) => {
    dispatch(DataStoreActions.entityListRequest(EntityType.NCompany));
    try {
      const companies = await NewnityApi.search(emptySearchFields, SearchEntity.Company);
      dispatch(DataStoreActions.entityListSuccess(EntityType.NCompany, companies));
    } catch (err) {
      dispatch(DataStoreActions.entityListError(EntityType.NCompany, err));
      dispatch(pushNotification(`Could not fetch companies: ${err}`, 'error'));
    }
  };
}

export function fetchCompanyInfo(companyId: number) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(companyDataFetchRequest());
    try {
      const companyInfo = await NewnityApi.getCompanyInfo(companyId);
      dispatch(DataStoreActions.entityUpsert(EntityType.NCompany, companyInfo));
      dispatch(companyDataFetchSuccess());
    } catch (err) {
      dispatch(companyDataFetchError(err));
      dispatch(pushNotification(`Could not fetch company info: ${err}`, 'error'));
    }
  };
}
export function setCurrentCompany(companyId: number) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setCompanyRequest(companyId));
    try {
      await NewnityApi.anthemChangeWorkgroup(companyId);
      dispatch(setWorkgroupAuthSuccess(companyId));
      dispatch(setCompanySuccess(companyId));
    } catch (err) {
      dispatch(setCompanyError(companyId, err));
      dispatch(pushNotification(`Could not switch workgroup in Anthem: ${err}`, 'error'));
    }
  };
}
export function fetchDefaultParentWorkgroup(companyWorkgroupId?: number) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(defaultParentWorkgroupRequest());
    try {
      if (!companyWorkgroupId) {
        const company = await NewnityApi.getDefaultParentWorkgroup();
        // dispatch(DataStoreActions.entityUpsert(EntityType.NParentCompanyWorkgroup, company));
        dispatch(defaultParentWorkgroupSuccess(company.id));
      } else {
        const company = await NewnityApi.getDefaultParentWorkgroup(companyWorkgroupId);
        // dispatch(DataStoreActions.entityUpsert(EntityType.NParentCompanyWorkgroup, company));
        dispatch(updateCurrentParentWorkgroup(company.id));
      }
    } catch (err) {
      dispatch(defaultParentWorkgroupError(err));
      dispatch(pushNotification(`Could not fetch company info: ${err}`, 'error'));
    }
  };
}

export function saveCompanyInfo(companyData: NCompany) {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    dispatch(companySaveRequest(companyData.id));
    try {
      const state = getState() as { newnity: NewnityState };
      const createMode = companyData.id === 0;
      const company = await NewnityApi.saveCompanyInfo(companyData, createMode);
      dispatch(DataStoreActions.entityUpsert(EntityType.NCompany, company));

      const blade = denormalize(BladeSelectors.selectActiveBlades(state)).find(
        (e) => e.type === CompanyBladeType
      );
      if (blade) {
        dispatch(BladeActions.setTitle(blade.id, company.name));
      } else {
        throw 'Company info blade not found';
      }
      await NewnityApi.anthemChangeWorkgroup(company.id);
      dispatch(companySaveSuccess(company.id));
      const notificationMessageKey = createMode
        ? 'newnity.edit.company.toast.create.success'
        : 'newnity.edit.company.toast.success';
      dispatch(pushNotification(i18next.t(notificationMessageKey), 'success'));
    } catch (err) {
      dispatch(companySaveError(err));
      dispatch(pushNotification(`Could not save company info: ${err}`, 'error'));
    }
  };
}

export function openCompanyEditBlade(
  companyId: number = 0,
  companyName: string = i18n.t('newnity.edit.company.blade.create.title')
) {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    const state = getState() as { newnity: NewnityState; dataStore: DataStoreState };
    const currentCompanyFetchingData = state.newnity.currentCompany.fetchingData;

    dispatch(openBlade('', CompanyBladeType, { title: companyName }));

    if (companyId && !currentCompanyFetchingData) {
      dispatch(fetchCompanyInfo(companyId));
    }
  };
}

export const fetchLocations = (companyId: number) => {
  return async (dispatch: Dispatch<any>) => {
    if (!companyId) {
      return;
    }
    dispatch(DataStoreActions.entityListRequest(EntityType.NLocation));
    try {
      const locations = await NewnityApi.getLocations(companyId);
      dispatch(DataStoreActions.entityListSuccess(EntityType.NLocation, locations));
    } catch (err) {
      dispatch(DataStoreActions.entityListError(EntityType.NLocation, err));
      dispatch(pushNotification(`Could not fetch locations: ${err}`, 'error'));
    }
  };
};

export const fetchLocation = (id: number) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const location = await NewnityApi.getLocation(id);
    } catch (err) {
      dispatch(pushNotification(`Could not fetch location: ${err}`, 'error'));
    }
  };
};

export const search = (fields: SearchFields, entity: EntityType) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(DataStoreActions.entityListRequest(entity));
    dispatch(searchSetSearched(entity, fields));
    try {
      const results = await NewnityApi.search(fields, entityToSearchEntity(entity));
      dispatch(DataStoreActions.entityListSuccess(entity, results));
    } catch (err) {
      dispatch(DataStoreActions.entityListError(entity, err));
      dispatch(pushNotification(`Could not fetch search results: ${err}`, 'error'));
    }
  };
};

const entityToSearchEntity = (entity: EntityType) => {
  switch (entity) {
    case EntityType.NCompanySearchResult:
      return SearchEntity.Company;
    case EntityType.NLocationSearchResult:
      return SearchEntity.Location;
    case EntityType.NDeviceSearchResult:
      return SearchEntity.Device;
    default:
      return SearchEntity.Company;
  }
};

export const saveLocationData = (companyId: number, location: NLocation, bladeId?: string) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(locationSaveRequest(companyId, location));
      const createMode = location.workgroupId === 0;
      const savedLocation = await NewnityApi.saveLocation(companyId, location, createMode);
      dispatch(DataStoreActions.entityUpsert(EntityType.NLocation, savedLocation));
      dispatch(locationSaveSuccessful(companyId, savedLocation));
      dispatch(locationFinishEdit());

      const notificationMessageKey = createMode
        ? 'newnity.edit.location.toast.create.success'
        : 'newnity.edit.location.toast.success';
      dispatch(pushNotification(i18next.t(notificationMessageKey), 'success'));
      if (bladeId) {
        dispatch(BladeActions.closeBlade(bladeId));
      }
    } catch (err) {
      dispatch(locationSaveError(companyId, location, err));
      dispatch(pushNotification(`Could not fetch search results: ${err}`, 'error'));
    }
  };
};

export const deleteLocation = (locationId: number, locationName: string, bladeId: string) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(locationDeleteRequest(locationId, locationName));
      const status = await NewnityApi.deleteLocations([locationId]);
      if (status === 200) {
        dispatch(
          DataStoreActions.entityDelete(EntityType.NLocation, {
            id: locationId,
            name: locationName,
          })
        );
        dispatch(
          DataStoreActions.entityDelete(EntityType.NLocation, {
            id: locationId,
            name: locationName,
          })
        );
        dispatch(locationDeleteSuccess(locationId, locationName));
        dispatch(locationFinishEdit());
        dispatch(BladeActions.closeChildrenBlades(bladeId));
      } else {
        dispatch(locationDeleteError(locationId, locationName, ''));
        dispatch(pushNotification(`Could not delete location ${locationName}`, 'error'));
      }
    } catch (err) {
      dispatch(locationDeleteError(locationId, locationName, err));
      dispatch(pushNotification(`Could not delete location ${locationName}: ${err}`, 'error'));
    }
  };
};

export function saveDevice(device: NDevice) {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    dispatch(deviceSaveRequest());
    try {
      let createMode = false;
      if (device.id === 0 || !device.id) {
        createMode = true;
      }

      let saveResult: { error: string; device: NDevice };

      const state = getState();

      const streamFn = async (hardwareId: number, hardwareName: string) => {
        const newVisualChannelId = device.visualChannelId ? device.visualChannelId : 0;
        const visualStreamId = device.visualStreamId;
        const audioOutputType = device.audioOutputForVisuals;

        // if we have a visualChannelId and the audio output type is AllZones/Right/Left
        if (newVisualChannelId > 0 && ((audioOutputType !== undefined) && audioOutputType >= StreamAudioOutput.AllZones)) {
          // instantiate a stream
          const streamModel = {
            id: device.visualStreamId ? device.visualStreamId : 0,
            channelId: newVisualChannelId,
            name: `Default Video Stream for device ${device.name}`,
            channelName: '',
            hardwareId: hardwareId,
            hardwareName: hardwareName,
            x: 0,
            y: 0,
            width: 100,
            height: 100,
            audioOutputType: audioOutputType,
            rowVersion: '',
          };

          // if the device doesn't already have a stream to the visual channel
          if (!visualStreamId) {
            // create a new stream and link it to the visualChannelId
            await NewnityApi.createStream(streamModel);
          } else { // if the device already gas a stream...
            // ...get the stream from the datastore...
            const stream = DataStoreSelectors.getDataStoreItem(
              state,
              EntityType.Stream,
              visualStreamId
            ) as Stream;

            // ... and update it
            const updatedStream = await NewnityApi.updateStream({
              ...stream,
              audioOutputType: streamModel.audioOutputType,
              channelId: streamModel.channelId,
            });

            // upsert the updated stream in the dataStore
            DataStoreActions.entityUpsert(EntityType.Stream, updatedStream);
          }
        } else {
          // if there's no visualChannelId selected and we are in edit mode (not create)...
          if (!createMode && visualStreamId) {
            const stream = DataStoreSelectors.getDataStoreItem(
              state,
              EntityType.Stream,
              visualStreamId
            ) as Stream;
            // ...delete the stream
            await NewnityApi.deleteStream(visualStreamId);
            dispatch(DataStoreActions.entityDelete(EntityType.Stream, stream));
          }
        }
      };

      if (createMode) {
        /* in create mode we first save the device and then create the
        stream because we need the devide id when creating the stream */
        saveResult = await NewnityApi.saveDevice(device, createMode);
        streamFn(saveResult.device.id, saveResult.device.name);
      } else {
        /* in edit mode we first update the stream because we might need to delete it.
        if we delete the stream, then the updated device will come back without a visual
        stream attached. so when we upsert it in the dataStore it will be legit :) */
        streamFn(device.id, device.name);
        saveResult = await NewnityApi.saveDevice(device, createMode);
      }

      if (saveResult.error) {
        throw new Error(saveResult.error);
      }

      const updatedDevice = saveResult.device;
      updatedDevice.deviceMode =
        updatedDevice.leftZone || updatedDevice.rightZone
          ? DeviceMode.DualZone
          : DeviceMode.SingleZone;
      
      /* besides cleanup, creating/updating music streams
      and addressing, this part also handles publishing */
      try {
        if (device.deviceMode === DeviceMode.SingleZone) {
          await NewnityApi.assignDeviceToPlaylist(
            updatedDevice.id,
            device.stationId ? device.stationId : 0,
            device.channelId,
            updatedDevice.serialNumber
          );

          const channel = DataStoreSelectors.getDataStoreItem(
            state,
            EntityType.Channel,
            device.channelId
          ) as Channel;

          updatedDevice.stationId = device.stationId;
          updatedDevice.stationName = device.stationName;
          updatedDevice.channelId = device.channelId;
          updatedDevice.channelIdWorkgroup = channel ? channel.workgroupId : 0;
          updatedDevice.leftZone = 0;
          updatedDevice.rightZone = 0;
          updatedDevice.leftZonePlaylist = 0;
          updatedDevice.rightZonePlaylist = 0;
        } else {
          await NewnityApi.assignZonePlaylistToDevice(
            updatedDevice.id,
            device.leftZone as number,
            device.leftZonePlaylist as number,
            device.rightZone as number,
            device.rightZonePlaylist as number,
            updatedDevice.serialNumber
          );

          const leftChannel = DataStoreSelectors.getDataStoreItem(
            state,
            EntityType.Channel,
            device.leftZone ? device.leftZone : 0
          ) as Channel;

          const rightChannel = DataStoreSelectors.getDataStoreItem(
            state,
            EntityType.Channel,
            device.rightZone ? device.rightZone : 0
          ) as Channel;

          updatedDevice.stationId = 0;
          updatedDevice.stationName = '';
          updatedDevice.channelId = 0;
          updatedDevice.leftZone = device.leftZone;
          updatedDevice.rightZone = device.rightZone;
          updatedDevice.leftZoneWorkgroup = leftChannel ? leftChannel.workgroupId : 0;
          updatedDevice.rightZoneWorkgroup = rightChannel ? rightChannel.workgroupId : 0;
          updatedDevice.leftZonePlaylist = device.leftZonePlaylist;
          updatedDevice.rightZonePlaylist = device.rightZonePlaylist;
        }
      } catch (err) {
        dispatch(pushNotification(err.message, 'error'));
      }

      dispatch(DataStoreActions.entityUpsert(EntityType.NDevice, updatedDevice as NDevice));
      dispatch(deviceSaveSuccess());
      dispatch(deviceFinishEdit());

      const notificationMessageKey = createMode
        ? 'newnity.device.edit.toast.create.success'
        : 'newnity.device.edit.toast.success';
      dispatch(pushNotification(i18next.t(notificationMessageKey), 'success'));

      const blade = denormalize(BladeSelectors.selectActiveBlades(getState())).find(
        (e) => e.id === DeviceBladeType
      );

      if (blade) {
        dispatch(BladeActions.setTitle(blade.id, device.name));
      }
    } catch (err) {
      dispatch(deviceSaveError(err.message));
      dispatch(pushNotification(`Could not save device: ${err.message}`, 'error'));
    }
  };
}

export function fetchDevice(deviceId: number) {
  return async (dispatch: Dispatch<any>) => {
    try {
      const device = await NewnityApi.getDevice(deviceId);
      if (!device) {
        throw 'Internal Server Error';
      }

      dispatch(DataStoreActions.entityUpsert(EntityType.NDevice, device as NDevice));
    } catch (err) {
      dispatch(DataStoreActions.entityListError(EntityType.NDevice, err));
      dispatch(pushNotification(`Could not fetch device: ${err}`, 'error'));
    }
  };
}

export const fetchPrograms = () => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    if (
      DataStoreSelectors.getDataStoreItemsFetching(getState(), EntityType.NProgram).fetchComplete
    ) {
      return;
    }

    dispatch(DataStoreActions.entityListRequest(EntityType.NProgram));
    try {
      const programs = await NewnityApi.anthemGetAllPrograms();
      dispatch(DataStoreActions.entityListSuccess(EntityType.NProgram, programs));
    } catch (err) {
      dispatch(DataStoreActions.entityListError(EntityType.NDevice, err));
      dispatch(pushNotification(`Could not fetch Anthem programs: ${err}`, 'error'));
    }
  };
};

export const fetchDevices = (companyId: number) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(DataStoreActions.entityListRequest(EntityType.NDevice));
    try {
      const devices = await NewnityApi.getDevices(companyId);
      dispatch(DataStoreActions.entityListSuccess(EntityType.NDevice, devices));
    } catch (err) {
      dispatch(DataStoreActions.entityListError(EntityType.NDevice, err));
      dispatch(pushNotification(`Could not fetch devices: ${err}`, 'error'));
    }
  };
};

export const fetchZone = (zoneId: number, workgroupId: number) => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    dispatch(channelFetchRequest(zoneId));
    try {
      const state = getState().newnity as NewnityState;
      const currentWorkgroupId = state.currentWorkgroupAuth.workgroupId;

      // change the current workgroup used for anthem authentication
      if (currentWorkgroupId !== workgroupId) {
        dispatch(changeWorkgroupAuthRequest(workgroupId));
        await NewnityApi.anthemChangeWorkgroup(workgroupId);
        dispatch(setWorkgroupAuthSuccess(workgroupId));
      }

      const zone = await NewnityApi.getZone(zoneId);
      const stations = await NewnityApi.getZoneStations(workgroupId, zoneId);
      dispatch(DataStoreActions.entityUpsert(EntityType.Channel, zone));
      dispatch(channelFetchSuccessful(zoneId, zone, stations));
    } catch (err) {
      dispatch(channelFetchError(zoneId, err));
      dispatch(pushNotification(`Could not fetch zone: ${err}`, 'error'));
    }
  };
};

export const fetchZones = (workgroupId: number) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(DataStoreActions.entityListRequest(EntityType.Channel));
    try {
      const zones = (await NewnityApi.getMusicChannels(workgroupId)) as Zone[];
      dispatch(DataStoreActions.entityListSuccess(EntityType.Channel, zones));
    } catch (err) {
      dispatch(DataStoreActions.entityListError(EntityType.Channel, err));
      dispatch(pushNotification(`Could not fetch zones: ${err}`, 'error'));
    }
  };
};

export const saveZoneData = (companyId: number, zone: Zone) => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    dispatch(channelSaveRequest(companyId, zone));
    try {
      const savedZone = { ...zone };
      const createMode = zone.id === 0;
      const { channelId, channelVersion } = await NewnityApi.saveZone(zone, createMode);
      dispatch(channelSaveSuccessful(companyId, savedZone));
      savedZone.id = channelId;
      savedZone.rowVersion = channelVersion;
      dispatch(DataStoreActions.entityUpsert(EntityType.Channel, savedZone));
      const notificationMessageKey = createMode
        ? 'newnity.edit.zone.toast.create.success'
        : 'newnity.edit.zone.toast.success';
      dispatch(pushNotification(i18next.t(notificationMessageKey), 'success'));

      const blade = denormalize(BladeSelectors.selectActiveBlades(getState())).find(
        (e) => e.type === ZoneEditBladeType
      );

      if (blade) {
        dispatch(
          BladeActions.closeBlade(
            blade.id,
            openBlade(blade.parentId, ZoneEditBladeType, {
              zoneId: savedZone.id,
              zoneName: savedZone.name,
              companyId: companyId,
              workgroupId: savedZone.workgroupId,
            })
          )
        );
      }
    } catch (err) {
      dispatch(pushNotification(`Could not save zone: ${err.message}`, 'error'));
    }
  };
};

export const deleteZone = (zoneId: number, zoneName: string, bladeId?: string) => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    const notificationMessageKey = 'newnity.delete.zone.toast.error';
    try {
      dispatch(channelDeleteRequest(zoneId, zoneName));
      const status = await NewnityApi.deleteZone(zoneId);
      if (status.ok) {
        dispatch(
          DataStoreActions.entityDelete(EntityType.Zone, {
            id: zoneId,
            name: zoneName,
          })
        );

        const newnityState = getState().newnity as NewnityState;

        if (bladeId && newnityState.currentChannel.channel.id == zoneId) {
          dispatch(BladeActions.forceCloseBlade(bladeId));
        }

        const notificationMessageKey = 'newnity.delete.zone.toast.success';
        dispatch(channelDeleteSuccess(zoneId, zoneName));
        dispatch(pushNotification(i18n.t(notificationMessageKey, { name: zoneName }), 'success'));
      } else {
        dispatch(channelDeleteError(zoneId, zoneName, ''));
        dispatch(pushNotification(i18n.t(notificationMessageKey, { name: zoneName }), 'error'));
      }
    } catch (err) {
      dispatch(channelDeleteError(zoneId, zoneName, err));
      dispatch(pushNotification(i18n.t(notificationMessageKey, { name: zoneName }), 'error'));
    }
  };
};

export const fetchStation = (stationId: number, zoneId: number) => {
  return async (dispatch: Dispatch<any>, getState: () => { newnity: NewnityState }) => {
    try {
      dispatch(stationFetchRequest(stationId));
      const programAssetsItems = await NewnityApi.anthemGetMediaLibrary();
      const programAssets = programAssetsItems.reduce<ProgramAsset>((obj, item) => {
        obj[item.programId] = item.assetId;
        return obj;
      }, {});
      const station = await NewnityApi.getStation(stationId, zoneId);
      const state = getState();
      if (state.newnity.currentStation.fetchingData.id === stationId) {
        dispatch(stationFetchSuccess(station, programAssets));
      }
    } catch (err) {
      const state = getState();
      if (state.newnity.currentStation.fetchingData.id === stationId) {
        dispatch(pushNotification(`Could not fetch playlist: ${err}`, 'error'));
        dispatch(stationFetchError(stationId, err));
      }
    }
  };
};

export const fetchPlaylistsForZone = (
  zoneId: number,
  companyId: number,
  leftOrRight: 'right' | 'left' | undefined
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const playlists = await NewnityApi.getZoneStations(companyId, zoneId);
      dispatch(fetchPlaylistsForZoneSuccess(zoneId, playlists, leftOrRight));
    } catch (err) {
      dispatch(pushNotification(`Could not fetch playlist: ${err}`, 'error'));
    }
  };
};

export const deleteStation = (
  stationId: number,
  stationName: string,
  channelId: number,
  bladeId?: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    const notificationMessageKey = 'newnity.delete.station.toast.error';
    try {
      dispatch(stationDeleteRequest(stationId, stationName));
      const status = await NewnityApi.deleteStation(stationId, channelId);
      if (status.ok) {
        dispatch(
          DataStoreActions.entityDelete(EntityType.NStation, {
            id: stationId,
            name: stationName,
          })
        );

        const newnityState = getState().newnity as NewnityState;

        if (newnityState.currentStation.station.id == stationId) {
          dispatch(BladeActions.forceCloseBlade(bladeId));
        }

        const notificationMessageKey = 'newnity.delete.station.toast.success';
        dispatch(stationDeleteSuccess(stationId, stationName));
        dispatch(
          pushNotification(i18n.t(notificationMessageKey, { name: stationName }), 'success')
        );
      } else {
        dispatch(stationDeleteError(stationId, stationName, ''));
        dispatch(pushNotification(i18n.t(notificationMessageKey, { name: stationName }), 'error'));
      }
    } catch (err) {
      dispatch(stationDeleteError(stationId, stationName, err));
      dispatch(pushNotification(i18n.t(notificationMessageKey, { name: stationName }), 'error'));
    }
  };
};

export function deleteDevice(deviceId: number, bladeId: string) {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(deviceDeleteRequest(deviceId));
      const result = await NewnityApi.deleteDevice(deviceId);

      if (result.error) {
        throw result.error;
      }

      dispatch(DataStoreActions.entityDelete(EntityType.NDevice, { id: deviceId, name: '' }));
      dispatch(deviceDeleteSuccess());
      dispatch(deviceFinishEdit());
      dispatch(BladeActions.closeChildrenBlades(bladeId));
    } catch (err) {
      dispatch(deviceDeleteError(err));
      dispatch(pushNotification(`Could not delete device: ${err}`, 'error'));
    }
  };
}

export const saveStationData = (station: NStation, bladeId?: string) => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    try {
      dispatch(stationSaveRequest(station));
      const createMode = station.id === 0;
      const state = getState() as { newnity: NewnityState };
      const stationState = createMode
        ? state.newnity.currentStation.createSchedule
        : state.newnity.currentStation.editSchedule;

      let savedStation: NStation;

      savedStation = await NewnityApi.saveStation(station, stationState, createMode);
      dispatch(stationSaveSuccessful(station));

      if (createMode) {
        station.id = savedStation.id;
      }

      // unset the previous default station if the current one is set as default
      if (station.isDefault) {
        const defaultStation = DataStoreSelectors.NStation.selectDefaultStation(state);
        if (defaultStation && defaultStation.id !== station.id) {
          const oldDefaultStation: NStation = { ...defaultStation, isDefault: false };
          dispatch(DataStoreActions.entityUpsert(EntityType.NStation, oldDefaultStation));
        }
      }

      dispatch(DataStoreActions.entityUpsert(EntityType.NStation, station));
      const notificationMessageKey = createMode
        ? 'newnity.edit.station.toast.create.success'
        : 'newnity.edit.station.toast.success';
      dispatch(pushNotification(i18next.t(notificationMessageKey), 'success'));
      if (bladeId) {
        dispatch(
          BladeActions.closeBlade(bladeId, [
            stationFinishEdit(),
            cleanupStationEdit(createMode ? 0 : station.id),
          ])
        );
      }
    } catch (err) {
      dispatch(stationSaveError(station, err));
      dispatch(pushNotification(`Could not save playlist: ${err}`, 'error'));
    }
  };
};

export const parseImportFile = (remotePath: string) => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    try {
      dispatch(parseImportFileRequest(remotePath));
      const response = await NewnityApi.checkImportFile(remotePath);
      const state = getState() as { newnity: NewnityState };

      if (state.newnity.import.parseFileFetchState.id !== remotePath) {
        return;
      }

      dispatch(parseImportFileSuccess(remotePath, response));
    } catch (err) {
      dispatch(parseImportFileError(remotePath, err));
      dispatch(pushNotification(`Could not parse import file: ${err}`, 'error'));
    }
  };
};

export const importFile = (remotePath: string, companyId: number) => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    try {
      dispatch(importFileRequest(remotePath));
      const response = await NewnityApi.importFile(remotePath, companyId);
      const state = getState() as { newnity: NewnityState };

      if (state.newnity.import.importFetchState.id !== remotePath) {
        return;
      }

      dispatch(importFileSuccess(remotePath, response));
      dispatch(pushNotification(`File import completed`, 'success'));
    } catch (err) {
      dispatch(importFileError(remotePath, err));
      dispatch(pushNotification(`Could not import file: ${err}`, 'error'));
    }
  };
};

export const fetchTemplatesAsync = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(DataStoreActions.entityListRequest(EntityType.TemplateLibrary));
      const templates: TemplateLibrary[] = await NewnityApi.getTemplateLibraries();
      dispatch(DataStoreActions.entityListSuccess(EntityType.TemplateLibrary, templates));
    } catch (err) {
      dispatch(DataStoreActions.entityListError(EntityType.TemplateLibrary, err));
      dispatch(pushNotification(`Could not fetch template libraries: ${err}`, 'error'));
    }
  };
};

export const fetchVisual = (visualId: number) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(channelFetchRequest(visualId));
    try {
      const visual = await NewnityApi.getVisual(visualId);
      dispatch(DataStoreActions.entityUpsert(EntityType.Channel, visual));
      dispatch(channelFetchSuccessful(visualId, visual));
    } catch (err) {
      dispatch(channelFetchError(visualId, err));
      dispatch(pushNotification(`Could not fetch visual: ${err}`, 'error'));
    }
  };
};

export const fetchVisuals = (workgroupId: number) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(DataStoreActions.entityListRequest(EntityType.Channel));
    try {
      const result = await NewnityApi.getVisualChannels(workgroupId);
      dispatch(DataStoreActions.entityListSuccess(EntityType.Channel, result));
    } catch (err) {
      dispatch(DataStoreActions.entityListError(EntityType.Channel, err));
      dispatch(pushNotification(`Could not fetch visuals: ${err}`, 'error'));
    }
  };
};

export const saveVisualData = (companyId: number, visual: Visual) => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    dispatch(channelSaveRequest(companyId, visual));
    try {
      const savedVisual = { ...visual };
      const createMode = visual.id === 0;
      const { channelId, channelVersion } = await NewnityApi.saveVisual(visual, createMode);
      dispatch(channelSaveSuccessful(companyId, savedVisual));
      savedVisual.id = channelId;
      savedVisual.rowVersion = channelVersion;
      dispatch(DataStoreActions.entityUpsert(EntityType.Channel, savedVisual));
      const notificationMessageKey = createMode
        ? 'newnity.edit.zone.toast.create.success'
        : 'newnity.edit.zone.toast.success';
      dispatch(pushNotification(i18next.t(notificationMessageKey), 'success'));

      const blade = denormalize(BladeSelectors.selectActiveBlades(getState())).find(
        (e) => e.type === VisualsEditBladeType
      );

      if (blade) {
        dispatch(
          BladeActions.closeBlade(
            blade.id,
            openBlade(blade.parentId, VisualsEditBladeType, {
              visualId: savedVisual.id,
              visualName: savedVisual.name,
              companyId: savedVisual.workgroupId,
            })
          )
        );
      }
    } catch (err) {
      dispatch(pushNotification(`Could not save visual channel: ${err.message}`, 'error'));
    }
  };
};

export const deleteVisual = (visualId: number, visualName: string, bladeId?: string) => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    const notificationMessageKey = 'newnity.delete.zone.toast.error';
    try {
      dispatch(channelDeleteRequest(visualId, visualName));
      const status = await NewnityApi.deleteZone(visualId);
      if (status.ok) {
        dispatch(
          DataStoreActions.entityDelete(EntityType.Zone, {
            id: visualId,
            name: visualName,
          })
        );

        const newnityState = getState().newnity as NewnityState;

        if (bladeId && newnityState.currentChannel.channel.id == visualId) {
          dispatch(BladeActions.forceCloseBlade(bladeId));
        }

        const notificationMessageKey = 'newnity.delete.zone.toast.success';
        dispatch(channelDeleteSuccess(visualId, visualName));
        dispatch(pushNotification(i18n.t(notificationMessageKey, { name: visualName }), 'success'));
      } else {
        dispatch(channelDeleteError(visualId, visualName, ''));
        dispatch(pushNotification(i18n.t(notificationMessageKey, { name: visualName }), 'error'));
      }
    } catch (err) {
      dispatch(channelDeleteError(visualId, visualName, err));
      dispatch(pushNotification(i18n.t(notificationMessageKey, { name: visualName }), 'error'));
    }
  };
};

export const fetchChannels = (workgroupId: number) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(DataStoreActions.entityListRequest(EntityType.Channel));
    try {
      const result = await NewnityApi.getChannels(workgroupId);
      dispatch(DataStoreActions.entityListSuccess(EntityType.Channel, result));
    } catch (err) {
      dispatch(DataStoreActions.entityListError(EntityType.Channel, err));
      dispatch(pushNotification(`Could not fetch channels: ${err}`, 'error'));
    }
  };
};

export const fetchStream = (streamId: number) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const stream = await NewnityApi.getStream(streamId);
      dispatch(DataStoreActions.entityUpsert(EntityType.Stream, stream));
    } catch (err) {
      dispatch(pushNotification(`Could not fetch stream: ${err}`, 'error'));
    }
  };
};

export const fetchOnHoldChannels = (workgroupId: number) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(DataStoreActions.entityListRequest(EntityType.Channel));
    try {
      const result = await NewnityApi.getOnHoldChannels(workgroupId);
      dispatch(DataStoreActions.entityListSuccess(EntityType.Channel, result));
    } catch (err) {
      dispatch(DataStoreActions.entityListError(EntityType.Channel, err));
      dispatch(pushNotification(`Could not fetch on hold channels: ${err}`, 'error'));
    }
  };
};

export const fetchOnHold = (onHoldId: number) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(channelFetchRequest(onHoldId));
    try {
      const onHold = await NewnityApi.getOnHold(onHoldId);
      dispatch(DataStoreActions.entityUpsert(EntityType.Channel, onHold));
      dispatch(channelFetchSuccessful(onHoldId, onHold));
    } catch (err) {
      dispatch(channelFetchError(onHoldId, err));
      dispatch(pushNotification(`Could not fetch on hold channel: ${err}`, 'error'));
    }
  };
};

export const saveOnHoldData = (companyId: number, onHold: OnHold) => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    dispatch(channelSaveRequest(companyId, onHold));
    try {
      const savedOnHold = { ...onHold };
      const createMode = onHold.id === 0;
      const { channelId, channelVersion } = await NewnityApi.saveOnHold(onHold, createMode);
      dispatch(channelSaveSuccessful(companyId, savedOnHold));
      savedOnHold.id = channelId;
      savedOnHold.rowVersion = channelVersion;
      dispatch(DataStoreActions.entityUpsert(EntityType.Channel, savedOnHold));
      const notificationMessageKey = createMode
        ? 'newnity.edit.onHold.toast.create.success'
        : 'newnity.edit.onHold.toast.success';
      dispatch(pushNotification(i18next.t(notificationMessageKey), 'success'));

      const blade = denormalize(BladeSelectors.selectActiveBlades(getState())).find(
        (e) => e.type === OnHoldEditBladeType
      );

      if (blade) {
        dispatch(
          BladeActions.closeBlade(
            blade.id,
            openBlade(blade.parentId, OnHoldEditBladeType, {
              onHoldId: savedOnHold.id,
              onHoldName: savedOnHold.name,
              companyId: savedOnHold.workgroupId,
            })
          )
        );
      }
    } catch (err) {
      dispatch(pushNotification(`Could not save on hold channel: ${err.message}`, 'error'));
    }
  };
};

export const deleteOnHold = (onHoldId: number, onHoldName: string, bladeId?: string) => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    const notificationMessageKey = 'newnity.delete.onHold.toast.error';
    try {
      dispatch(channelDeleteRequest(onHoldId, onHoldName));
      const status = await NewnityApi.deleteOnHold(onHoldId);
      if (status.ok) {
        dispatch(
          DataStoreActions.entityDelete(EntityType.Zone, {
            id: onHoldId,
            name: onHoldName,
          })
        );

        const newnityState = getState().newnity as NewnityState;

        if (bladeId && newnityState.currentChannel.channel.id == onHoldId) {
          dispatch(BladeActions.forceCloseBlade(bladeId));
        }

        const notificationMessageKey = 'newnity.delete.onHold.toast.success';
        dispatch(channelDeleteSuccess(onHoldId, onHoldName));
        dispatch(pushNotification(i18n.t(notificationMessageKey, { name: onHoldName }), 'success'));
      } else {
        dispatch(channelDeleteError(onHoldId, onHoldName, ''));
        dispatch(pushNotification(i18n.t(notificationMessageKey, { name: onHoldName }), 'error'));
      }
    } catch (err) {
      dispatch(channelDeleteError(onHoldId, onHoldName, err));
      dispatch(pushNotification(i18n.t(notificationMessageKey, { name: onHoldName }), 'error'));
    }
  };
};

export const anthemChangeWorkgroup = (workgroupId: number) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(changeWorkgroupAuthRequest(workgroupId));
      await NewnityApi.anthemChangeWorkgroup(workgroupId);
      dispatch(setWorkgroupAuthSuccess(workgroupId));
    } catch (err) {
      console.error(`Error changing anthem authentication: ${err}`);
      dispatch(setWorkgroupAuthError(workgroupId, err));
    }
  };
};