import { FormControl, MenuItem } from '@material-ui/core';
import { VirtualizedSelect } from 'app/components/forms/virtualizedSelect';
import { NStation } from 'app/dataStore';
import { Channel, ChannelType } from 'app/dataStore/types';
import { FieldProps } from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../device.jss';

export const useStationSelect = (
  playlists: NStation[],
  presetStation: boolean,
  setPresetStation: (newValue: boolean) => void,
  channel: Channel
) => {
  const classes = useStyles();
  const [t] = useTranslation();

  const stationSelectRowValue = useCallback((value: any) => {
    return <>{value ? value.name : 'None'}</>;
  }, []);

  const stationSelectRowRenderer = useCallback(({ field, form }: FieldProps) => {
    return (items: NStation[], callback?: (value: any) => void) => {
      return ({ index, style }: { index: number; style: any }) => {
        const station = items[index];
        if (station.id === 0) {
          return (
            <MenuItem
              style={style}
              key={0}
              value={0}
              onClick={() => {
                form.setFieldValue(field.name, 0);
                form.setFieldValue('stationName', station.name);
                form.setFieldTouched(field.name, true);

                if (callback) {
                  callback(0);
                }
              }}
            >
              None
            </MenuItem>
          );
        }

        return (
          <MenuItem
            style={style}
            key={station.id}
            value={station.id}
            onClick={(e) => {
              form.setFieldValue(field.name, station.id);
              form.setFieldValue('stationName', station.name);
              form.setFieldTouched(field.name, true);

              if (callback) {
                callback(station);
              }
            }}
          >
            {station.name}
          </MenuItem>
        );
      };
    };
  }, []);

  return useCallback(
    ({ field, form }: FieldProps) => {
      const defaultStation = playlists.filter((x) => x.isDefault);

      if (presetStation && defaultStation.length && field.value !== defaultStation[0].id) {
        form.setFieldValue(field.name, defaultStation[0].id);
      }

      const shouldClearSelect =
        !playlists.find((e) => e.id === field.value) &&
        field.value &&
        field.value !== form.initialValues.stationId;
      if (shouldClearSelect) {
        form.setFieldValue(field.name, 0);
      }

      return (
        <FormControl className={classes.selectMargin} style={{ flexGrow: 1 }}>
          <VirtualizedSelect
            value={field.value}
            disabled={channel.channelType === ChannelType.OnHold || channel.id === 0}
            placeholder={t<string>('newnity.device.edit.station')}
            itemSize={48}
            maxHeight={250}
            onClickCallback={() => setPresetStation(false)}
            autoComplete="something"
            MenuProps={{ PaperProps: { style: { maxHeight: 250 } } }}
            items={[{ id: 0 }, ...playlists]}
            rowRenderer={stationSelectRowRenderer({ field, form, meta: {} as any })}
            renderValue={stationSelectRowValue}
            noValueLabel="None"
          ></VirtualizedSelect>
        </FormControl>
      );
    },
    [presetStation, playlists, channel]
  );
};
