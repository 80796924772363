import { DeviceMode, StreamAudioOutput } from '../newnity/duck/types';

export const areaName = 'dataStore';

export interface IdName {
  id: number;
  name: string;
}

export interface Player extends IdName {
  serial: string;
  siteId: number;
  workgroupId: number;
  stationId: number;
  stationName: string;
}

export enum PlayerType {
  Unknown = 0,
  Windows = 1,
  Android = 2,
  Brightsign = 3,
  SamsungSSP = 4,
}

export interface Site extends IdName {
  workgroupId: number;
  oracleNumber: string;
  clientSiteId: string;
  timezone: string;
  country: string;
  city: string;
  phoneNumber: string;
  state: string;
  zipCode: string;
  address: string;
}

export interface Stream extends IdName {
  channelId: number;
  channelName: string;
  hardwareId: number;
  hardwareName: string;

  x: number;
  y: number;
  width: number;
  height: number;
  audioOutputType: StreamAudioOutput;

  rowVersion: string;
}

export interface Workgroup extends IdName {
  parentId: number;
}

export interface TemplateLibrary extends IdName {}

export interface User extends IdName {
  firstName: string;
  lastName: string;
  email: string;
  login: string;
  password: string;
  accountExpDate?: string;
  passwordExpDate?: string;
  isSaving?: boolean;
  lastWelcomeEmailDate: string;
  lockLevel?: number;
  lockReason?: string;
  accessRights?: string;
  extendedApps?: number;

}

export interface Zone extends Channel {
  channelType: ChannelType.Music;
}

export interface Visual extends Channel {
  channelType: ChannelType.Video | ChannelType.VOD;
}

export interface OnHold extends Channel {
  channelType: ChannelType.OnHold;
}

export interface Channel extends IdName {
  channelType: ChannelType;
  workgroupName: string;
  workgroupId: number;
  isExplicit: boolean;
  rowVersion: string;
}

export enum ChannelType {
  Video = 1,
  Music = 2,
  MusicMessages = 3,
  VOD = 4,
  OnHold = 5,
}

export interface Brand extends IdName {
  logoPath: string;
}

export interface NCompany extends Workgroup {
  number: string;

  // Address fields
  country: string;
  city: string;
  state: string;
  zipCode: string;
  streetAddress: string;

  // Contact fields
  firstName: string;
  lastName: string;
  phone: string;
  email: string;

  // Template Libraries
  templateLibraries: TemplateLibrary[];
}

// tslint:disable-next-line: no-empty-interface
export interface NLocation extends Site {}

export interface NDevice extends IdName {
  deviceTypeId: PlayerType;
  deviceTypeName: string;
  serialNumber: string;
  salesOrderNumber: string;
  description: string;
  locationName: string;
  locationNumber: string;
  locationId: number;
  stationId?: number;
  stationName?: string;
  channelId: number;
  channelIdWorkgroup?: number;
  deviceMode: DeviceMode;
  leftZone?: number;
  leftZoneWorkgroup?: number;
  leftZonePlaylist?: number;
  rightZone?: number;
  rightZoneWorkgroup?: number;
  rightZonePlaylist?: number;
  visualChannelId?: number;
  audioOutputForVisuals?: StreamAudioOutput;
  visualStreamId?: number;
}

export interface NProgram extends IdName {
  genre: string;
  description: string;
  albumArt: string;
  artists: string;
}

export interface NStation extends IdName {
  description: string;
  isDefault: boolean;
  isTemporary: boolean;
  isFirstPlaylist: boolean;
  channelId: number;
  blockRemoteScrolling: boolean;
  slotsIds: number[];
  defaultSlotId: number;
  players: Player[];
}

export interface NCompanySearchResult extends IdName {
  name: string;
  number: string;
  city: string;
  country: string;
}

export interface NLocationSearchResult extends IdName {
  locationNumber: string;
  locationName: string;
  siteNumber: string;
  companyName: string;
  companyId: number;
}

export interface NDeviceSearchResult extends IdName {
  serial: string;
  salesOrder: string;
  deviceDescription: string;
  locationName: string;
  locationNumber: string;
  siteNumber: string;
  companyName: string;
  companyId: number;
}

export interface NormalizedIdNames<T extends IdName> {
  [id: number]: T;
}

export interface EntityState<T extends IdName> {
  items: NormalizedIdNames<T>;
  itemsFetching: DataItemsFetchStatus;
}

export interface DataItemsFetchStatus {
  isFetching: boolean;
  error: string;
  fetchComplete: boolean;
}

export interface ImportSkippedRow {
  id: number;
  row: number;
  columnName: string;
  name: string;
}

export interface ImportResult {
  skipped: ImportSkippedRow[];
  skippedRowCount: number;
  total: number;
  completed: number;
}

export enum NewnityEntityType {
  ImportSkippedRow = 'importSkippedRow',
  Workgroup = 'workgroup',
  Brand = 'brand',
  TemplateLibrary = 'templateLibrary',
  Player = 'player',
  Site = 'site',
  Stream = 'stream',
  Channel = 'channel',
  Zone = 'zone',
  Visual = 'visual',
  User = 'user',
  NCompany = 'nCompany',
  NDevice = 'nDevice',
  NLocation = 'nLocation',
  NCompanySearchResult = 'nCompanySearchResult',
  NLocationSearchResult = 'nLocationSearchResult',
  NDeviceSearchResult = 'nDeviceSearchResult',
  NParentCompanyWorkgroup = 'nParentCompanyWorkgroup',
  HasDeviceSettings = 'hasDeviceSettings',
  NStation = 'nStation',
  NProgram = 'nProgram',
}

export enum EntityWithSettings {
  NCompany = 'nCompany',
  NDevice = 'nDevice',
  NLocation = 'nLocation',
}

export interface CountryInfo extends IdName {
  code: string;
}

export interface DataStoreState {
  [NewnityEntityType.Player]: EntityState<Player>;
  [NewnityEntityType.Site]: EntityState<Site>;
  [NewnityEntityType.Workgroup]: EntityState<Workgroup>;
  [NewnityEntityType.Brand]: EntityState<Brand>;
  [NewnityEntityType.TemplateLibrary]: EntityState<TemplateLibrary>;
  [NewnityEntityType.User]: EntityState<User>;
  [NewnityEntityType.Channel]: EntityState<Channel>;
  [NewnityEntityType.Stream]: EntityState<Stream>;

  [NewnityEntityType.NCompany]: EntityState<NCompany>;
  [NewnityEntityType.NLocation]: EntityState<NLocation>;
  [NewnityEntityType.NDevice]: EntityState<NDevice>;

  [NewnityEntityType.NCompanySearchResult]: EntityState<NCompanySearchResult>;
  [NewnityEntityType.NLocationSearchResult]: EntityState<NLocationSearchResult>;
  [NewnityEntityType.NDeviceSearchResult]: EntityState<NDeviceSearchResult>;
  [NewnityEntityType.NStation]: EntityState<NStation>;
  [NewnityEntityType.NProgram]: EntityState<NProgram>;
}
