import { ImportResult } from 'app/dataStore/types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

export interface ImportSummaryRow {
  entityName: string;
  detailsBladeTitle: string;
  data: ImportResult;
}

export interface ImportSummaryProps {
  rows: ImportSummaryRow[];
}

export interface ImportSummaryActions {
  showDetails: (title: string, data: ImportResult) => void;
}

export const ImportSummary: React.FunctionComponent<ImportSummaryProps &
  ImportSummaryActions> = React.memo(props => {
  const [t] = useTranslation();

  const { showDetails } = props;

  const openSkippedDetailsBlade = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const entityName = e.currentTarget.name;
      const row = props.rows.find(r => r.entityName === entityName);
      if (row) {
        showDetails(row.detailsBladeTitle, row.data);
      }
    },
    [showDetails, props.rows]
  );
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>{t<string>('newnity.import.summary.entity')}</TableCell>
          <TableCell align="right">{t<string>('newnity.import.summary.total')}</TableCell>
          <TableCell align="right">{t<string>('newnity.import.summary.completed')}</TableCell>
          <TableCell align="right">{t<string>('newnity.import.summary.errors')}</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.rows.filter(x => x.entityName !== "Device settings").map(r => (
          <TableRow key={r.entityName}>
            <TableCell component="th" scope="row">
              {r.entityName}
            </TableCell>
            <TableCell align="right">{r.data.total}</TableCell>
            <TableCell align="right">{r.data.completed}</TableCell>
            <TableCell align="right">{r.data.skippedRowCount}</TableCell>
            <TableCell>
              {r.data.skippedRowCount > 0 && (
                <IconButton name={r.entityName} size="small" onClick={openSkippedDetailsBlade}>
                  <KeyboardArrowRight />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
});
